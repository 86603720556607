//==============================================================================
// D365 OData Attribute Parser
//
// In creating this, it became clear that "attributes" is a general term.
//
// Product attributes are a first-class entity. They have their own dedicated
// fields and schema.
//
// The more generic term "attributes" is used as a generic extension system.
// Almost every entity (including Product Attributes) has an ExtensionProperties
// field for jamming extra information into. In some cases, D365 handles these
// natively, such as sales order line and header attributes, or customer
// attributes. Attributes using ExtensionProperties use the
// CommerceProperty and CommercePropertyValue types.
//
// A major note about ExtensionProperty-based attributes: they have fields
// for many different data types, but there's no indicator as to which
// of the fields is actually used. The consumer of the data is expected to
// know the data type ahead of time. In the case of D365-native attribute users
// such as customers and sales orders, only the string type appears to work.
//
// This module includes parsers for generic ExtensionProperties-based attributes
// and Product Attributes. Though different Product Attribute retail server
// calls use the same AttributeValue entity to transmit the data, they appear
// to return different fields from that entity. Because of this, two different
// parser calls are included here. They currently share the same implementation,
// but by including different call signatures we have the ability to diverge
// the implementations if the need arises.
//
// A note about Product Attributes: These contain both a friendly name,
// displayed to users in back office, and an internal name. The internal name
// can be the same as the friendly name, but by convention we've been pushing
// for a PascalCase scheme. At present, the searchByCriteria call returns only
// the unfriendly name, and the GetAttributeValues call returns only the
// friendly name. We don't have a known method of correlating the two. That may
// be an argument for keeping the friendly and unfriendly names identical.
//==============================================================================
import {
    CommerceProperty,                   // Generic attributes stored in ExtensionProperties
    CommercePropertyValue,              // Generic attributes stored in AttributeValue
    AttributeValue, AttributeDataType,  // Product attributes or Product Search Result attributes
    CustomerAttribute,                  // Customer attributes
    IDictionary,
} from '@msdyn365-commerce/retail-proxy';

//==============================================================================
// INTERFACES AND CONSTANTS
//==============================================================================
export type CommerceValueTypes = string | number | boolean | Date;

// This is me giving up on proper typing. If doing this in TypeScript is possible, I don't know how.
// For the key "meta", the type should be a hash of strings.
// For all other keys, the type is a CommerceValueType.
export type AttributesWithMetadata = {
    [key: string]: CommerceValueTypes | IDictionary<string>
};

// Type to support both internal and friendly name for attributes
export type AttributesLocalized = {
    [key: string]: {
        friendlyName?: string,
        value?: CommerceValueTypes | IDictionary<string>
    }
};

// Return type for getTypeInfo()
type TypeData = {
    source: keyof AttributeValue,
    type: string,

    customerSource?: keyof CommercePropertyValue,
};

//==============================================================================
// FUNCTIONS
//==============================================================================

//----------------------------------------------------------
// This version handles ExtensionProperties conversion.
//
// While these have a bunch of potential fields, the value
// is always stored within StringValue.
// There isn't a data type field, so if the content wasn't
// in StringValue we wouldn't know where to find it.
//----------------------------------------------------------
export function convertExtensionProperties(attributeList: CommerceProperty[]): IDictionary<CommerceValueTypes> {
    const output = {};

    attributeList.forEach(entry => {
        if (entry.Key) {
            output[entry.Key] = entry.Value?.StringValue;       // Ecommerce seems to always use strings.
        }
    });

    return output;
}

//----------------------------------------------------------
// For these attributes, the Name is always the friendly
// name.
// KeyName and ExtensionProperties aren't defined.
//
// These results seem to be missing CurrencyValue,
// FloatValue, DateTimeOffsetValue
// I don't know if those data types aren't allowed here.
//
// Return value: Hash of key: property values with variable
// types. There is also a "meta" key containing a hash of
// all the attribute keys and their corresponding types.
//
// Example:
// {
//     flavor: 'bland',
//     calories: 5300,
//     meta: {
//         flavor: 'string',
//         calories: 'number'
//     }
// }
//----------------------------------------------------------
export function convertProductAttributes(attributeList: AttributeValue[]): AttributesWithMetadata {
    const output = {
        meta: {}
    };

    attributeList.forEach(attribute => {
        // These should always be present, but TypeScript insists they can be undefined.
        if (attribute.Name && attribute.DataTypeValue) {
            const typeData = getTypeInfo(attribute.DataTypeValue);
            output[attribute.Name] = attribute[typeData.source];       // Ecommerce seems to require strings
            output.meta[attribute.Name] = typeData.type;
        }
    });

    return output;
}

//----------------------------------------------------------
// For these attributes, the
// Name/KeyName/ExtensionProperties is always the unfriendly
// name.
//
// These results are only missing the Swatches field.
//
// Though the returned value is a bit different, at present
// the implementation for convertProductAttributes works for
// search results.
// If they diverge in the future, extend this function.
//----------------------------------------------------------
export function convertSearchAttributes(attributeList: AttributeValue[]): AttributesWithMetadata {
    return convertProductAttributes(attributeList);
}

//----------------------------------------------------------
// For these attributes, the Name is the friendly
// name while KeyName is the internal name.
//
// This makes use of combined attributes containing both
// internal and friendly name to support localization.
//
// Return value: Hash of internal name keys containing an object with both the
// friendly name and attribute value There is also a "meta" key containing a
// hash of all the attribute keys and their corresponding types.
//
// Example:
// {
//     flavor: {
//       friendlyName: Flavor,
//       value: 'bland'
//     },
//     calories: {
//       friendlyName: Calories,
//       value: 5300
//     },
//     meta: {
//         flavor: 'string',
//         calories: 'number'
//     }
// }
//----------------------------------------------------------
export function convertProductAttributesLocalized(attributeList: AttributeValue[]): AttributesLocalized {
    const output = {
        meta: {}
    };

    attributeList.forEach(attribute => {
        if (attribute.KeyName && attribute.DataTypeValue) {
            const typeData = getTypeInfo(attribute.DataTypeValue);
            output[attribute.KeyName] = {
                friendlyName: attribute.Name,
                value: attribute[typeData.source]
            };
            output.meta[attribute.KeyName] = typeData.type;
        }
    });

    return output;
}

//----------------------------------------------------------
//----------------------------------------------------------
export function convertCustomerAttributes(attributeList: CustomerAttribute[] | undefined): AttributesWithMetadata {
    const output = {
        meta: {}
    };

    attributeList?.forEach(attribute => {
        // These should always be present, but TypeScript insists they can be undefined.
        if (attribute.Name && attribute.DataTypeValue) {
            const typeData = getTypeInfo(attribute.DataTypeValue);
            output[attribute.Name] = attribute.AttributeValue && typeData.customerSource && attribute.AttributeValue[typeData.customerSource];
            output.meta[attribute.Name] = typeData.type;
        }
    });

    return output;
}

//----------------------------------------------------------
// Finds the source field and type for an attribute
//
// This should probably be extended to better support
// currency, which seems to be two fields glued together.
//----------------------------------------------------------
function getTypeInfo(type: AttributeDataType): TypeData {

    // Awkward, but this helps TypeScript enforce strict type checking
    type TypeMapType = {
        [key in AttributeDataType]: TypeData
    };

    const typeMap: TypeMapType = {
        [AttributeDataType.None]:      {source: 'TextValue', type: 'string'},           // We need to choose something
        [AttributeDataType.Currency]:  {source: 'CurrencyValue', type: 'number'},       // Should also include CurrencyCode, which is a string?
        [AttributeDataType.DateTime]:  {source: 'DateTimeOffsetValue', type: 'Date'},
        [AttributeDataType.Decimal]:   {source: 'FloatValue', type: 'number'},
        [AttributeDataType.Integer]:   {source: 'IntegerValue', type: 'number', customerSource: 'IntegerValue'},
        [AttributeDataType.Text]:      {source: 'TextValue', type: 'string', customerSource: 'StringValue'},
        [AttributeDataType.TrueFalse]: {source: 'BooleanValue', type: 'boolean', customerSource: 'BooleanValue'},
        [AttributeDataType.Video]:     {source: 'TextValue', type: 'string'},
        [AttributeDataType.Image]:     {source: 'TextValue', type: 'string'}
    };

    return typeMap[type];
}
